import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { BreakpointProvider } from 'react-socks'
import { Flex, Box } from '@rebass/grid'
import { useStaticQuery, graphql } from 'gatsby'

import Footer from '../components/footer'
import Head from '../components/head'
import Navbar from '../components/Navbar'
import Section from '../components/Section'
import Services from '../components/home/services'
import { D, M } from '../components/breakpoints'

const PAGE_TITLE = 'Hubtype - Terms and Conditions'

const Bg = styled.div`
  position: absolute;
  overflow: hidden;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: white;
`

const Title = styled.h1`
  font-family: Heebo;
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 45px;
  color: #ffffff;
`
const Subtitle = styled.h2`
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px; /* identical to box height */

  color: #333333;
`
const Paragraph = styled.p`
  font-family: Heebo;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px; /* identical to box height */

  color: #333333;
  padding-left: 0px;
  @media (min-width: 512px) {
    padding-left: ${props => (props.noPadding ? '0px' : '68px')};
  }
  margin-left: ${props => (props.marginL ? '10px' : '0px')};
  margin-top: ${props => (props.marginT ? '5px' : '0px')};

  width: 100%;
  @media (min-width: 512px) {
    width: 75%;
  }
`
const Service = styled.h3`
  font-family: Heebo;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 29px;
  color: #333333;
  padding-left: 0px;
  @media (min-width: 512px) {
    padding-left: 30px;
  }
`
const TermsPage = props => {
  const { bgHeader, bgHeaderMobile } = useStaticQuery(
    graphql`
      query {
        bgHeader: file(relativePath: { eq: "home/bg_header_terms.png" }) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
        bgHeaderMobile: file(
          relativePath: { eq: "home/bg_header_mobile_terms.png" }
        ) {
          childImageSharp {
            fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  )
  const Description = styled.h2`
    font-family: Heebo;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #333333;
    width: 100%;
    @media (min-width: 512px) {
      width: 80%;
    }
  `
  const Update = styled.p`
    font-family: Heebo;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 16px; /* identical to box height */
    color: #696973;
    padding-top: 20px;
    padding-bottom: 25px;
  `
  let bgDesktop = (
    <Img
      fluid={bgHeader.childImageSharp.fluid}
      style={{
        marginTop: 76,
      }}
    />
  )

  let bgMobile = (
    <Img
      fluid={bgHeaderMobile.childImageSharp.fluid}
      style={{
        marginTop: 80,
      }}
    />
  )

  const ServiceContainer = props => (
    <Flex justifyContent={'row'}>
      <Service>{props.section}</Service>
      <Paragraph
        noPadding
        marginL
        marginT
        dangerouslySetInnerHTML={{ __html: props.children }}
      />
    </Flex>
  )

  const Header = props => {
    return (
      <>
        <D>
          <Section pt={200} mt={76} background={bgDesktop}>
            <Title style={{ position: 'absolute', top: 165 }}>
              Terms of service
            </Title>
          </Section>
        </D>

        <M>
          <Section
            style={{
              paddingBottom: 38,
            }}
            pt={190}
            px={15}
            background={bgMobile}
          >
            <Title style={{ position: 'absolute', top: 108 }}>
              Terms of service
            </Title>
          </Section>
        </M>
      </>
    )
  }

  const services = [
    {
      section: '12.1',
      text:
        '<b>“Agreement”</b> means this Customer Agreement, together with the Order Form and all its Schedules, Appendix and attachments as may be amended, superseded or supplemented from time to time, in writing by the Parties.',
    },
    {
      section: '12.2',
      text:
        '<b>“Chat Participant”</b> means an individual who communicates with a Customer’s Client or on behalf of a Customer’s Client through the HUBTYPE Platform.',
    },
    { section: '12.3', text: '<b>“Customer”</b> means the customer.' },
    {
      section: '12.4',
      text:
        '<b>“Customer"s Client</b> means any clients of the Customer and any authorized resellers of the Customer and their clients.',
    },
    {
      section: '12.5',
      text:
        '<b>“Customer Data”</b> means any data, information, content, records, and files that Customer (or any of its Users, Customer’s Clients or Chat Participants) loads, receives through, transmits to or enters into the HUBTYPE Platform orotherwise provides to HUBTYPE, including Personal Data.',
    },
    {
      section: '12.6',
      text:
        '<b>“Documentation”</b> means the implementation guides; help information andother User documentation regarding the Service that provided by HUBTYPE to Customer in electronic or other form.',
    },
    {
      section: '12.7',
      text:
        '<b>“HUBTYPE Platform”</b> means platform owned by HUBTYPE, including software, hardware, systems, HUBTYPE APIs and HUBTYPE SDKs, that allow companies to interact with their customers through the most popular messaging apps combining chatbots and human agents.',
    },
    {
      section: '12.8',
      text:
        '<b>“HUBTYPE Property”</b> means all ideas, concepts, inventions, systems, platforms, software, interfaces, tools, utilities, templates, forms, techniques, methods, processes, algorithms, know-how, trade secrets and other technologies and information acquired, created, developed or licensed by HUBTYPE prior to or outside the scope of this Agreement and any improvement, modification, extension or other derivative works thereof andall intellectual property rights there to including without limitation the Service, HUBTYPE APIs, HUBTYPE SDKs, Documentation, Report Templates, andAggregate Data. HUBTYPE Property excludes Customer Data and Results.',
    },
    {
      section: '12.9',
      text:
        '<b>“HUBTYPE API”</b> means the application programming interface, sample source code, tools, instructions, documentation, and other materials made available by HUBTYPE to Customer to allow for the interoperability between an Integrated Product and the HUBTYPE Platform.',
    },
    {
      section: '12.10',
      text:
        '<b>“HUBTYPE SDK”</b> means the software development kits made available by HUBTYPE for the development of software applications for each of iOS, Android, or the web, that interoperates with the HUBTYPE Platform.',
    },
    {
      section: '12.11',
      text:
        '<b>“Messaging Channels”</b> means any and all (i) web, in-app or other messaging channel enabled by a HUBTYPE SDK, or (ii) a Messaging Platform that is integrated with the HUBTYPE Platform.',
    },
    {
      section: '12.12',
      text:
        '<b>“Messaging Platform”</b> means any third-party or Customer platform or communication service that may be used by Chat Participants to transmitand receive messages with Customer’s Clients, including but not limited to SMS, RCS, email, chat applications, voice assistants, VOIP services, and other platforms or services that HUBTYPE may support in the future.',
    },
    {
      section: '12.13',
      text:
        '<b>“Order Form”</b> shall mean an order form referencing this Agreement that has been mutually agreed to and executed by the parties.',
    },
    {
      section: '12.14',
      text:
        '<b>“Personal Data</b> means any information relating to an identified or identifiable natural person (“data subject”) that is loaded, transmitted to or enters into the HUBTYPE Platform by Customer, Users, Customer’s Clients and Chat Participants.',
    },
    {
      section: '12.15',
      text:
        '<b>“Report Template</b> means the content, formatting, look and feel of the templates used for the reports, charts, graphs and other presentation in which the Results are presented to Customer.',
    },
    {
      section: '12.16',
      text:
        '<b>“Results”</b> means the work product resulting from the Service delivered to Customer by HUBTYPE through the Service, to the extent based on the Customer Data. Results expressly exclude all HUBTYPE Property.',
    },
    {
      section: '12.17',
      text:
        '<b>“Service”</b> means the services provided by HUBTYPE to Customer that facilitates the communication between the Customer’s Clients and Chat Participants through Messaging Platforms, as more particularly described in the Documentation, as identified in an Order Form, as updated from time to time by HUBTYPE in its sole discretion.',
    },
    {
      section: '12.18',
      text:
        ' <b>“Sites”</b> means websites operated by HUBTYPE that are made accessible to Customer under this Agreement.',
    },
    {
      section: '12.19',
      text:
        '<b>“User"</b> means an individual who is authorized by Customer to use a Service, for whom Customer has purchased a subscription (or in the case of anyServices provided by us without charge, for whom a Service has been provided), and to whom Customer (or, when applicable, HUBTYPE at your request) have supplied a user identification and password (for Services utilizing authentication). Users may include, for example, Customers’employees, consultants, contractors and agents, and third parties which Customer transact business.',
    },
  ]
  //services.map((e,i) => <ServiceContainer section={e.section} text={e.text}/>)

  return (
    <BreakpointProvider>
      <Head
        alternates={props.pageContext.i18n}
        autopilot={props.pageContext.autopilot}
        lang={props.pageContext.lang}
        title={PAGE_TITLE}
        uri={props.pageContext.relativeUrl}
      />
      <Navbar
        theme="dark"
        style={{
          background: 'linear-gradient(180deg, #333333 0%, #6500CB 1266.15%)',
          borderBottom: 'none',
        }}
        {...props}
      />
      <Header />
      <Section px={[15, 0]} background={<Bg />}>
        <Description>
          These terms of service (“agreement” or “terms of service”) governs
          your purchase and receipt of the services offered by metis solutions,
          s.l. (“hubtype”) and sets forth the legally binding terms and
          conditions for your use of the sites and the service (each as defined
          below). <br /> <br />
          By using the subscription service, you agree to be bound by this
          agreement. If you are entering into this agreement on behalf of a
          company or other legal entity, you represent that you have the
          authority to bind such entity to these terms and conditions.
        </Description>
        <Box pt={30} mb={50} style={{ borderBottom: '1px solid #DADADA' }} />
        <Subtitle>1. Service</Subtitle>
        <Service>1.1 Service License</Service>
        <Paragraph>
          HUBTYPE grants Customer a nonexclusive, nontransferable right and
          license (without right to sublicense) during the Service term
          specified in the Order Form, to (a) access and use the Service as
          described in an Order Form, solely to facilitate communications
          between Customer’s Client and Chat Participants, and (b) download,
          install and use the HUBTYPE APIs or HUBTYPE SDKs in connection with
          Customer’s authorized use of the Service. The Service is made
          available to Customer solely as hosted by or on behalf of HUBTYPE, and
          nothing in this Agreement shall be construed to grant Customer any
          right to receive any copy of the Service or any software (other than
          the HUBTYPE APIs or HUBTYPE SDKs). Customer’s access and use of the
          Service shall comply with all other conditions set forth in the Order
          Form and Documentation (such as, for example, any requirements
          regarding data formats, number of permitted users or prohibited uses).
        </Paragraph>
        <Service>1.2 Service Access and Availability</Service>
        <Flex>
          <Box width={0.099}>
            <Paragraph>a)</Paragraph>
          </Box>
          <Box ml={-10} width={0.901}>
            <Paragraph noPadding>
              Customer Systems. Customer is responsible for (i) complying with
              its obligations under the applicable terms of use related to all
              applicable third party Messaging Platforms, (ii) taking all steps
              as necessary to enable interoperability between the HUBTYPE
              Platform and all applicable third party Messaging Platforms,
              including acquiring the necessary approvals and API keys, whether
              directly or via Customer’s Clients, to access these messaging
              channels as a business, (iii) providing all equipment,
              subscriptions and credentials necessary for HUBTYPE to receive the
              Customer Data, and (iv) providing all servers, devices, storage,
              software (other than the HUBTYPE APIs or HUBTYPE SDKs), databases,
              network and communications equipment and ancillary services needed
              to connect to, access or otherwise use the Service at its facility
              (collectively, “Customer Systems”). Customer shall ensure that all
              Customer Systems are compatible with the Service and comply with
              all configurations and specifications described in the
              Documentation.
            </Paragraph>
          </Box>
        </Flex>
        <Flex>
          <Box width={0.099}>
            <Paragraph>b)</Paragraph>
          </Box>
          <Box ml={-10} width={0.901}>
            <Paragraph noPadding>
              Service Access. As part of the implementation process, Customer
              will identify a primary administrative username and password that
              will be used to set up Customer’s account. Customer may use the
              administrative user name and password to create subaccounts for
              its Users (each with unique login IDs and passwords). Customer
              shall be responsible for the acts or omissions of any person who
              accesses the Service using passwords or access procedures provided
              to or created by Customer. HUBTYPE reserves the right to refuse
              registration of, or to cancel, login IDs that violate the terms
              and conditions set forth in this Agreement. Customer agrees to
              notify HUBTYPE immediately upon learning of any unauthorized use
              of Customer’s account or any other breach of security. From time
              to time, HUBTYPE’ personnel may log in to the Service under
              Customer’s account in order to maintain or improve the Service,
              including providing Customer assistance with technical or billing
              issues. Customer hereby acknowledges and consents to such access.
              This access will be always performed using a HUBTYPE
              administrative account and never logging into an individual’s user
              account or under a customer user’s identity. Documentation.
            </Paragraph>
          </Box>
        </Flex>
        <Service>1.3 Service Availability</Service>
        <Paragraph>
          HUBTYPE will use commercially reasonable efforts to maintain the
          Service availability to send and receive data, subject to downtimes
          resulting from maintenance, repairs and upgrades. HUBTYPE will attempt
          to notify Customer electronically via the Service in advance of any
          planned downtime. Notwithstanding the foregoing, HUBTYPE will not be
          liable for any failures in the Service or any other problems which are
          related to (a) the Customer Data, the Customer Systems, the Messaging
          Platform, the Messaging Channels, or (b) outages to any
          telecommunications or public Internet backbones, networks or servers,
          or other equipment or service outside of HUBTYPE’ facilities or
          control.
        </Paragraph>
        <Service>1.4 Service Support</Service>
        <Paragraph>
          HUBTYPE will provide Customer with telephone and e-mail support
          forCustomer’s use of the Service during HUBTYPE’ regular business
          hours. Customer agrees that HUBTYPE is not responsible to provide
          support for any issues resulting from problems, errors or inquiries
          related to Customer Systems.
        </Paragraph>
        <Service>1.5 Professional Services</Service>
        <Paragraph>
          From time to time, Customer may request and HUBTYPE may agree to
          provide certain custom development, consulting, training or other
          professional services as mutually agreed to by the parties
          (“Professional Services”). The terms and conditions of any such
          arrangement for Professional Services shall be set forth in a separate
          statement of work executed by the parties that specifically references
          this Agreement (each, a “Statement of Work”), each of which shall be
          governed by the terms of this Agreement. Unless otherwise agreed to by
          the parties and set forth in the applicable Statement of Work, all
          intellectual property, and all rights embodied there in, that are
          created byHUBTYPE during the performance of Professional Services
          shall be owned solely and exclusively by HUBTYPE.
        </Paragraph>
        <Subtitle>2. Free demo</Subtitle>
        <Paragraph>
          If you are eligible for a free demo, we will make one or more Services
          available to Customer on a trial basis free of charge until the
          earlier of (a) the end of the free demo period for which Customer
          registered to use the applicable Services or (b) the start date of any
          Services subscriptions ordered by Customer for such Servicesor (c)
          termination by HUBTYPE in our sole discretion. Additional trial terms
          and conditions may appear on the trial registration web page. Any such
          additional terms and conditions are incorporated into this Agreement
          by reference and are legally binding.
        </Paragraph>
        <Subtitle>3. License restrictions and customer obligations</Subtitle>
        <Service>3.1 Service License Restrictions</Service>
        <Paragraph>
          Customer shall not directly or indirectly: (i) use the Service or any
          of HUBTYPE Property or Confidential Information to create any service,
          software or documentation that performs substantially the same
          functionality as the Service, (ii) disassemble, decompile, reverse
          engineer or use any other means to attempt to discover any source
          code, algorithms or trade secrets underlying the Service (except and
          only to the extent these restrictions are expressly prohibited by
          applicable statutory law), (iii) encumber, sublicense, transfer,
          distribute, rent, lease, time-share or use any HUBTYPE Property in any
          service bureau arrangement or otherwise for the benefit of any third
          party, (iv) copy,reproduce, translate, adapt, combine, create
          derivative works of or otherwise vary or modify any HUBTYPE Property,
          or (v) use or allow the transmission, transfer, export, re-export or
          other transfer of any software, technology or information it obtains
          or learns pursuant to this Agreement in violation of anyexport control
          or other laws and regulations of any relevant jurisdiction.
        </Paragraph>
        <Service>3.2 Unauthorized Use of Service</Service>
        <Paragraph>
          Customer shall not directly or indirectly: (i) use the HUBTYPE
          Platform other than as permitted by this Agreement ; (ii) use unique
          values or a continuous setof values as inputs to event attributes,
          (iii) interfere or attempt to interfere with the proper working of the
          Service or any activities conducted on the Service;(iv) by pass any
          privacy settings or measures HUBTYPE may use to prevent or restrict
          access to the Service (or other accounts, computer systems or networks
          connected to the Service); (v) run mail list, any form of
          auto-responder or “spam” on the Service; or (vi) use manual or
          automated software, devices, or other processes to “crawl” or “spider”
          any page of the Sites.
        </Paragraph>
        <Service>3.3 Privacy and Data Protection</Service>
        <Paragraph>
          Customer understands that Personal Data of Chat Participants will be
          treated in accordance with (i) HUBTYPE privacy policy located{' '}
          <a href="/privacy">here</a> and (ii) HUBTYPE Data Processing Agreement
          annexed to this document. Customer is responsible for any such
          Personal Data that Customer provides to HUBTYPE. Customer shall comply
          with all applicable laws relating to the collection and use of
          Personal Data and Customer Data.
        </Paragraph>
        <Subtitle>4. Orders, fees and payments</Subtitle>
        <Service>4.1 Order Form</Service>
        <Paragraph>
          The parties may enter into one or more Order Forms pursuant to this
          Agreement. Each Order Form shall specify the Service to be provided,
          Service Fees, the term during which the Service is to be provided,
          invoicing terms and any other terms mutually agreed to by the parties.
          HUBTYPE shall only be responsible to provide the Service identified in
          an Order Form for the term specified in the Order Form.
        </Paragraph>
        <Service>4.2 Service Fees</Service>
        <Paragraph>
          Customer shall pay a fee for the right to use the Service (“
          <b>Service Fees</b>”) in the amount and pursuant to the invoicing
          schedule set forth in the Order Form. Unless otherwise set forth in
          the Order Form, HUBTYPE shall invoice the Service Fees yearly in
          arrears.
          <br /> <br />
          The Services Fees do not include any current or future charges that
          may be assessed by Third Party Messaging Channels for access to or use
          of a channel. Such charges shall be the responsibility of Customer or
          Customer’s Client as applicable.
        </Paragraph>
        <Service>4.3 Professional Services Fees</Service>
        <Paragraph>
          Customer shall pay for Professional Services at the rates and pursuant
          to the invoicing schedule set forth in the applicable Statement of
          Work.
        </Paragraph>
        <Service>4.4 Payment Terms</Service>
        <Paragraph>
          Payments shall be made in Euros at HUBTYPE’ address (or to an account
          specified by HUBTYPE), in full without set-off, counter claim or
          deduction with inthirty (30) days of the date of the invoice. Past due
          amounts which are not subject to a good faith dispute shall bear a
          late payment charge, until paid, at the rate of 1.5% per month or the
          maximum amount permitted by law, whichever is less. Customer agrees to
          reimburse HUBTYPE for all costs (including attorneys’ fees) incurred
          by HUBTYPE in collecting late payments. In addition to its other
          rights and remedies, HUBTYPE may, at its option, suspend Customer’s
          access to the Service or terminate this Agreement in the event that
          Customer is not current in the payment of fees owed to HUBTYPE.
        </Paragraph>
        <Service>4.5 Taxes</Service>
        <Paragraph>
          All payments required by this Agreement are exclusive of taxes,
          duties, tariffs, levies, with holdings and similar assessments
          (including without limitation, sales taxes, use taxes and value added
          taxes). All amounts payable by Customer hereunder, including all
          Service Fees, shall be grossed-up for any with holding taxes imposed
          by any foreign government on Customer’s payment of such amounts to
          HUBTYPE.
        </Paragraph>
        <Subtitle>5. Confidientality</Subtitle>
        <Service>5.1 Scope</Service>
        <Paragraph>
          “Confidential Information” means all financial, business, operational,
          marketing or technical information disclosed by or for a party in
          relation to this Agreement whether disclosed in tangible, written,
          oral or electronic form, which is of a nature that should reasonably
          be considered to be confidential and proprietary. Without limitation,
          (a) the Service, HUBTYPE Property and pricing information are HUBTYPE’
          Confidential Information and (b) all Customer Data (including any
          Personal Data) shall be deemed Customer’s Confidential Information for
          purposes of this Section 5. Confidential Information expressly
          excludes any information (other than Personal Data) to the extent that
          a recipient can demonstrate such information is (a) already known by
          it prior to receipt for the disclosing party without restriction, (b)
          rightfully furnished to it without restriction by a third party not in
          breach of any obligation to the disclosing party, (c) generally
          available to the public without breach of this Agreement or (d)
          independently developed by the recipient without reference to or use
          of any of the disclosing party’s Confidential Information.
        </Paragraph>
        <Service>5.2 Confidentiality</Service>
        <Paragraph>
          Except for the specific rights expressly granted by this Agreement,
          the receiving party shall not use, copy or disclose any of the
          disclosing party’sConfidential Information without disclosing party’s
          prior written consent. The receiving party shall use the disclosing
          party’s Confidential Information solely for the purpose of exercising
          its rights and performing its obligations here under.The receiving
          party shall only disclose Confidential Information to its employees,
          contractors and agents (“
          <b>Representatives</b>
          ”) who have a need toknow for the purposes of this Agreement and are
          bound by substantially similar confidentiality obligations and shall
          use reasonable care to safeguard thedisclosing party’s Confidential
          Information. Each party shall be responsible for any breach of
          confidentiality by its Representatives, as applicable. Promptlyupon
          the disclosing party’s request at any time, the receiving party shall
          return all of the disclosing party’s tangible Confidential
          Information, permanently erase all Confidential Information in
          electronic form and destroy all information,records, copies,
          summaries, analyses and materials developed therefrom. Each party may
          disclose the general nature, but not the specific terms, of
          thisAgreement without the prior consent of the other party; provided,
          however, that either party may provide a copy of this Agreement or
          otherwise disclose its terms on a confidential basis in connection
          with any financing transaction ordue diligence inquiry.
        </Paragraph>
        <Service>5.3 Compelled Disclosure</Service>
        <Paragraph>
          Nothing herein shall prevent a party from disclosing this Agreement or
          any ofthe other’s Confidential Information as necessary pursuant to
          any court orderor any legal, regulatory, law enforcement or similar
          requirement or investigation; provided, prior to any such disclosure,
          the receiving party shall use reasonable efforts to (a) promptly
          notify the disclosing party in writing of such requirement to disclose
          and (b) cooperate with the disclosing party in protecting against or
          minimizing any such disclosure or obtaining a protective order.
        </Paragraph>
        <Subtitle>6. Propietary Rights</Subtitle>
        <Service>6.1 Results and Customer Data</Service>
        <Paragraph>
          Customer shall own all right, title and interest (including all
          intellectual property and other proprietary rights) in and to the
          Results, Customer Data and Customer Systems. Customer hereby grants
          HUBTYPE a nonexclusive, royalty-free right and license to access, use,
          copy, process and store theCustomer Data solely for the purpose of
          providing the Service. Customer agrees that the Service depends on the
          availability of the Customer Data. Customer will be solely responsible
          for all Customer Data collected from Users as a result of Customer’s
          use of the Service including the accuracy and completeness of such
          information. Unless otherwise set forth in the Order Form, HUBTYPE
          shall not have any obligation to store any Customer Data or Results.
          Except for the limited rights and licenses expressly granted here
          under,no other license is granted, no other use is permitted and
          Customer shall retain all right, title and interest (including all
          intellectual property and proprietary rights embodied therein) in and
          to the Results, Customer Data and Customer Systems.
        </Paragraph>
        <Service>6.2 Service Feedback</Service>
        <Paragraph>
          Customer may, from time to time and in its sole discretion, make
          suggestions for changes, modifications or improvements to the Service
          atsupport@hubtype.com (“Feedback”). All Feedback shall be solely owned
          by HUBTYPE (including all intellectual property rights therein and
          thereto) and shall also be HUBTYPE’ Confidential Information. Customer
          shall and hereby does make all assignments necessary to achieve such
          ownership.
        </Paragraph>
        <Service>6.3 HUBTYPE Property</Service>
        <Paragraph>
          The Customer acknowledges and agrees that the HUBTYPE Platform,
          including software, hardware and related documents, has been created
          by HUBTYPE, who will maintain all intellectual property rights, patent
          rights or anyother rights over the HUBTYPE Platform, and that it shall
          not be the object of ulterior modification, copy, alteration,
          reproduction, adaptation or translation bythe Customer.
          <br />
          <br />
          The structure, characteristics, codes, work methodology, information
          systems, development tools, know-how, methodologies, processes,
          technologies or algorisms of the HUBTYPE Platform are the property of
          HUBTYPE, or its providers, having been, when the latter, object of
          license or assignment by them, and are protected by the Spanish and
          international rules of intellectual property and patent rights, and
          cannot be the object of ulterior modification, copy, alteration,
          reproduction, adaptation or translation by the Customer.
          <br />
          <br />
          The provision by the Customer of the HUBTYPE Platform does not mean,
          in any case, the assignment of its ownership or the grant of a right
          of use in favor of the Customer other than the one set forth in this
          Agreement or the Order Forms.
          <br />
          <br />
          To the extent that HUBTYPE includes any HUBTYPE Property in the
          Results(including the Report Template), then subject to all terms and
          conditions of this Agreement, HUBTYPE agrees to grant Customer
          (without the right to sublicense) a nonexclusive, nontransferable,
          royalty-free right and license touse such HUBTYPE Property, including
          the HUBTYPE Platform, as combine dwith or embodied in the applicable
          Results, solely for Customer’s internalbusiness purposes in connection
          with its use of the Results. Except for the limited rights and
          licenses expressly granted here under, no other license isgranted, no
          other use is permitted and HUBTYPE (and its licensors) shall retain
          all right, title and interest (including all intellectual property and
          proprietary rights embodied therein) in and to the Service and the
          HUBTYPE Properties.
          <br />
          <br />
          Customer agrees that HUBTYPE is free to (i) collect, use and create
          derivative works of data regarding Service usage and performance
          derived from the Results; (ii) aggregate such data with other data to
          create compilations and analysis of such data (the “Aggregated Data”);
          and (iii) use, copy, modify,create derivative works of, publish and
          disclose such Aggregated Data in amanner that does not directly or
          indirectly identify Customer or any individual person. HUBTYPE shall
          own all right, title and interest to the Aggregated Dataand any
          derivative works thereof. In addition, HUBTYPE shall be free to
          reuseall general knowledge, experience, know-how, works and
          technologies(including ideas, concepts, processes and techniques)
          related to the Results or acquired during provision of the Service
          (including without limitation, that which it could have acquired
          performing the same or similar services for another customer).
        </Paragraph>
        <Subtitle>7. Warranties and Disclaimers</Subtitle>
        <Service>7.1 Customer Data</Service>
        <Paragraph>
          Customer represents and warrants that it owns all right, title and
          interest, or possesses sufficient license rights, in and to the
          Customer Data and PersonalData as may be necessary to permit the use
          contemplated under this Agreement.
        </Paragraph>
        <Service>7.2 Service</Service>
        <Paragraph>
          HUBTYPE represents and warrants to Customer that the Service will be
          provided in a professional manner in accordance with the terms of this
          Agreement and the Documentation. Any warranty claim under this Section
          7.2 must be made in writing within thirty (30) days after performance
          of the portion of the Services giving rise to the claim. HUBTYPE’ sole
          liability and Customer’s exclusive right and remedy for a breach of
          such warranty is for HUBTYPE to correct or re-perform the non
          conforming Service.
        </Paragraph>
        <Service>7.3 Disclamers</Service>
        <Paragraph>
          EXCEPT AS SPECIFICALLY PROVIDED FOR IN THIS SECTION 7, TO THEFULLEST
          EXTENT PERMITTED BY LAW, EACH PARTY HEREBY DISCLAIMS (FOR ITSELF, ITS
          AFFILIATES AND THEIR SUPPLIERS) ALL REPRESENTATIONS AND WARRANTIES,
          WHETHER EXPRESS ORIMPLIED, ORAL OR WRITTEN, INCLUDING WITHOUT
          LIMITATION, ALL IMPLIED WARRANTIES OF NON-INFRINGEMENT,
          MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE AND ALL
          WARRANTIES ARISING FROM ANY COURSE OF DEALING, COURSE OF PERFORMANCE
          OR USAGE OF TRADE. WITHOUT LIMITING THE FOREGOING, HUBTYPE MAKES NO
          WARRANTY THAT THE SERVICE WILL MEET CUSTOMER’S REQUIREMENTS OR BE
          UNINTERRUPTED, ERROR-FREE OR BUG-FREE. <br />
          <br /> CUSTOMER ACKNOWLEDGES THAT HUBTYPE’S PERFORMANCE OF ITS
          OBLIGATIONS UNDER THIS AGREEMENT IS DEPENDENT ON: (I)CUSTOMER HAVING
          TAKEN ALL STEPS NECESSARY TO ENABLE THE INTEROPERABILITY BETWEEN THE
          HUBTYPE PLATFORM AND ANY APPLICABLE INTEGRATED PRODUCT (THROUGH USE OF
          THE HUBTYPEAPI); AND (II) ACCESS TO SERVICES, SOFTWARE OR SYSTEMS OF
          THIRD PARTIES (INCLUDING THIRD PARTY SERVICE PROVIDERS OF ALL
          APPLICABLE THIRD PARTY MESSAGING PLATFORMS AND INTEGRATED PRODUCTS)
          AND DATA STORED OR MAINTAINED THEREON(COLLECTIVELY, THE “EXTERNAL
          DEPENDENCIES”). HUBTYPE IS NOT RESPONSIBLE FOR ANY EXTERNAL
          DEPENDENCIES AND WILL HAVE NO LIABILITY IN RESPECT OF ANY INTERFERENCE
          WITH CUSTOMER’S USE, CHAT PARTICIPANT’S USE, CUSTOMER’S CLIENT’S USE
          OR ANY ADMINISTRATIVE USER’S USE OF OR ACCESS TO THE HUBTYPE SOLUTION
          OR SECURITY OR PRIVACY BREACHES ARISING FROM OR ATTRIBUTABLE TO ANY
          EXTERNAL DEPENDENCIES, AND CUSTOMER WAIVES ANY AND ALL CLAIMS AGAINST
          HUBTYPE IN CONNECTION THERE WITH.
        </Paragraph>
        <Subtitle>8. Indemnification</Subtitle>
        <Service>8.1 HUBTYPE Infringement Indemnity</Service>
        <Paragraph>
          Except as provided below, HUBTYPE agrees to (a) defend Customer
          against any allegation demand, claim, action, proceeding or suit
          (each, a “Claim”) by athird party that Customer’s authorized use of
          the Service infringes any USpatent or copyright or misappropriates any
          trade secret of such third party and(b) indemnify Customer for
          settlement amounts or damages, liabilities, costsand expenses
          (including reasonable attorneys’ fees, “Loss(es)”) awarded to such
          third party by a court of competent jurisdiction or agreed to as part
          of amonetary settlement arising out of such Claim; provided, that (i)
          Customer promptly provides HUBTYPE with written notice thereof and
          reasonable cooperation, information, and assistance in connection
          therewith, and (ii) HUBTYPE shall have sole control and authority to
          defend, settle or compromise such Claim. If the Service becomes or, in
          HUBTYPE’ opinion, is likely to become, the subject of any injunction
          preventing its use ascontemplated here in, HUBTYPE may, at its option
          (1) obtain for Customer the right to continue using the Service or (2)
          replace or modify the Service so that it becomes non-infringing
          without substantially compromising its principal functions. If (1) and
          (2) are not reasonably available to HUBTYPE, then it may terminate
          this Agreement upon written notice to Customer and refund to Customer
          any unused prepaid Service Fees, pro-rated for the remainder of the
          prepaid period. HUBTYPE shall have no liability or obligation to
          Customer hereunder with respect to any Claim or Loss to the extent
          based upon (a) any unauthorized use of the Service, (b) any
          modification or combination of the Service with data, software,
          hardware, or systems not provided by HUBTYPE,(c) any portion of the
          Service that implements Customer’s specific requirements, (d)
          Customer’s continuing all egedly infringing activity after being
          notified to cease use as provided for herein or (e) Customer’s
          continuing use of any version of the HUBTYPE Properties after being
          provided modifications that would have avoided the alleged
          infringement. The foregoing states the soleand exclusive liability of
          HUBTYPE, and Customer’s sole and exclusive remedy, with respect to any
          actual or all eged violation of intellectual property rights by the
          Service or any part there of or by its use or operation.
        </Paragraph>
        <Service>8.2 Customer Indemnity</Service>
        <Paragraph>
          Customer agrees to (i) defend HUBTYPE against any Claim by a third
          party that results from or arises out of (a) any breach by Customer of
          any of its obligations in Section 3.3 hereof, (b) any breach by
          Customer of its representations and warranties set forth in Section
          7.1 hereof, or (c) any violation of any third party’s (including any
          of Customer’s Users, Customer’s Client or Chat Participant) right or
          Personal Data arising out of Customer’s useof the Service; and (ii)
          indemnify HUBTYPE for any Losses awarded by a court of competent
          jurisdiction or agreed to as part of a monetary settlement and arising
          out of such Claim; provided, that (i) HUBTYPE promptly provides
          Customers with written notice thereof and reasonable cooperation,
          information, and assistance in connection therewith, and (ii) Customer
          shall have sole control and authority to defend, settle or compromise
          such Claim
        </Paragraph>
        <Subtitle>9. Limitation of Liability</Subtitle>
        <Paragraph>
          IN NO EVENT SHALL EITHER PARTY BE LIABLE TO THE OTHER PARTY CONCERNING
          THE SUBJECT MATTER OF THIS AGREEMENT, REGARDLESS OF THE FORM OF ANY
          CLAIM OR ACTION (WHETHER IN CONTRACT, NEGLIGENCE, STRICT LIABILITY OR
          OTHERWISE), FOR ANY (A) LOSS ORINACCURACY OF OR DAMAGE TO DATA, LOSS
          OR INTERRUPTION OF USE, OR COST OF PROCURING SUBSTITUTE TECHNOLOGY,
          GOODS ORSERVICES, (B) INDIRECT, PUNITIVE, INCIDENTAL, RELIANCE,
          SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES INCLUDING, BUT NOT LIMITED
          TO, LOSS OF BUSINESS, REVENUES, PROFITS AND GOODWILL, EVEN IFADVISED
          OF THE POSSIBILITY OF SUCH DAMAGES, OR (C) DAMAGES, IN THE AGGREGATE,
          IN EXCESS OF THE TOTAL OF THE AMOUNTS PAID TO IT (IN THE CASE OF
          HUBTYPE) OR THE TOTAL OF THE AMOUNTS PAID ANDPAYABLE HEREUNDER (IN THE
          CASE OF CUSTOMER) DURING THE THEN CURRENT TERM OF THE ORDER FORM
          GIVING RISE TO THE CLAIM. THESE LIMITATIONS ARE INDEPENDENT FROM ALL
          OTHER PROVISIONS OF THIS AGREEMENT AND SHALL APPLY NOTWITH STANDING
          THE FAILURE OF ANY REMEDY PROVIDED HEREIN. THE LIMITATIONS SET FORTH
          IN THIS SECTION SHALL NOT APPLY TO ANY BREACH OF CONFIDENTIALITY OR
          PROPRIETARY RIGHTS OR FOR COST OF DEFENSE OR LIABILITIES ARISINGOUT OF
          A PARTY’S INDEMNIFICATION OBLIGATIONS HEREUNDER.
        </Paragraph>
        <Subtitle>10. Term and Termination</Subtitle>
        <Service>10.1 Term of Agreement</Service>
        <Paragraph>
          This Agreement shall commence on the Effective Date and continue in
          effect thereafter until the later of (i) such time as a party provides
          thirty (30) days prior written notice of termination to the other
          party if there is no OrderForm(s) then in effect or (ii) if there is
          one or more existing Order Form(s) then in effect, upon the expiration
          of the last to expire of such existing Order Forms.
        </Paragraph>
        <Service>10.2 Term of Order Form </Service>
        <Paragraph>
          Initial term shall be the term stated in the Order Form (the “Initial
          Term”).Thereafter, the Order Form will be extended automatically for
          additional terms of one (1) year each (each “Renewal Term”) at the end
          of the Initial Term and each Renewal Term (the Initial Term and all
          Renewal Term,collectively, as the “Term”), unless either party gives
          written notice of non-renewal at least thirty (30) days prior to the
          end of the then current Term. An Order Form shall not be subject to
          termination except as provided in Section10.3 hereof.
        </Paragraph>
        <Service>10.3 Termination of Order Form</Service>
        <Paragraph>
          An Order Form may be earlier terminated by either party (a) if the
          other party materially breaches a material term of this Agreement or
          the Order Form and fails to cure such breach within thirty (30) days
          after receiving written notice of such breach from the other party, or
          (b) immediately upon written notice, if the other party makes any
          assignment for the benefit of creditors, or a receiver, trustee in
          bankruptcy or similar officer is appointed to take charge ofany or all
          of the other party’s property, or the other party seeks protection
          under any bankruptcy, receivership, trust deed, creditors arrangement,
          composition or comparable proceeding or such a proceeding is
          instituted against the other party and is not dismissed within ninety
          (90) days, or the other party becomes insolvent or, without a
          successor, dissolves, liquidates or otherwise fails to operate in the
          ordinary course.
        </Paragraph>
        <Service>10.4 Effects of Termination</Service>
        <Paragraph>
          Upon any expiration or termination of this Agreement, all rights,
          obligations and licenses of the parties shall cease, except that all
          obligations that accrued prior to the effective date of termination
          (including without limitation, all payment obligations) and all
          remedies for breach of this Agreement shall survive, confidentiality,
          proprietary rights provisions, warranties and disclaimers),
          indemnification, limitation of liability, and general provisions shall
          survive. HUBTYPE has no obligation to retain any Customer Data or
          Results after the Term and will destroy all Customer Data and Results
          in its possession within ninety (90) days after the end of the Term;
          provided, upon Customer’s written request received within thirty (30)
          days after termination, HUBTYPE will deliver to Customer a copy of the
          Customer Data then currently stored by HUBTYPE (in the same format
          maintained by HUBTYPE)
        </Paragraph>
        <Subtitle>11. General Clauses</Subtitle>
        <Service>11.1 Entire Agreement</Service>
        <Paragraph>
          This Agreement (including the Order Form and all Statements of Work)
          constitutes the entire agreement, and supersedes all prior
          negotiations, understandings or agreements (oral or written), between
          the parties about the subject matter of this Agreement. Any
          inconsistent or additional terms on any related purchase order,
          confirmation or similar form, even if signed bythe parties after the
          date here of, shall have no force or effect under this Agreement. No
          waiver, consent or modification of this Agreement shall bind either
          party unless in writing and signed by the party against which
          enforcement is sought. The failure of either party to enforce its
          rights underthis Agreement at any time for any period will not be
          construed as a waiver of such rights. If any provision of this
          Agreement is determined to be illegal or unenforceable, that provision
          will be limited or eliminated to the minimum extent necessary so that
          this Agreement will otherwise remain in full force and effect and
          enforceable. Any use of the terms “include, ” “included” or
          “including” shall also be deemed to mean “but not limited to” or
          “without limitation.”
        </Paragraph>
        <Service>11.2 Governing Law and Dispute Resolution </Service>
        <Paragraph>
          This Agreement shall be governed by and construed in accordance with
          the laws of Spain, without regard to its conflicts of law provisions.
          Neither the United Nations Convention on Contracts for the
          International Sale of Goodsn or any implementation of the Uniform
          Computer Information Transactions Act in any jurisdiction shall apply
          to this Agreement. Except with respect to claims for injunctive or
          other equitable relief, which may be brought at any time before any
          court of competent jurisdiction, in the event of any dispute arising
          from or relating to the subject matter of this Agreement, the senior
          executive officers of the parties shall meet within thirty (30) days
          following receipt of notice of such dispute, and shall use good faith
          efforts to attempt toamicably resolve the dispute within such thirty
          (30) day period. In the event the parties are unable to amicably
          resolve the dispute within such thirty (30) day period, the Parties
          expressly agree to submit to the jurisdiction of the Courts of
          Barcelona (Spain), and expressly waive any other jurisdiction to which
          they may be entitled.In any action or proceeding to enforce or
          interpret this Agreement, the prevailing party will be entitled to
          recover from the other party its costs and expenses (including
          reasonable attorneys’ fees) incurred in connection with such action or
          proceeding and enforcing any judgment or order obtained.
        </Paragraph>
        <Service>11.3 Compliance with Laws</Service>
        <Paragraph>
          Each party shall comply with all applicable, laws and regulations in
          connection with the performance of its obligations and the exercise of
          its rights under this Agreement.
        </Paragraph>
        <Service>11.4 Remedies</Service>
        <Paragraph>
          Except as specifically provided otherwise, each right and remedy in
          this Agreement is in addition to any other right or remedy, which may
          be available at law or in equity. Each party agrees that, in the event
          of any breach or threatened breach of Section 5 (Confidentiality) or 6
          (Proprietary Rights) orthe scope of any license granted here under,
          the non-breaching party willsuffer irreparable damage for which it
          will have no adequate remedy at law. Accordingly, the non-breaching
          party shall be entitled to seek injunctive and other equitable
          remedies to prevent or restrain such breach or threatened breach,
          without the necessity of posting any bond.
        </Paragraph>
        <Service>11.5 Force Majeure</Service>
        <Paragraph>
          On the event that either party is prevented from performing, or is
          unable to perform, any of its obligations under this Agreement (except
          payment obligations) due to any cause beyond its reasonable control,
          the affected party shall give written notice thereof to the other
          party and its performance shall be extended for the period of delay or
          inability to perform due to such occurrence.
        </Paragraph>
        <Service>11.6 Publicity</Service>
        <Paragraph>
          HUBTYPE will not use Customer’s trademarks, service marks and logos
          (“Customer Marks”) in press releases or as a testimonial without
          obtaining Customer’s prior written approval. Customer hereby consents
          to inclusion ofits name and logos in customer lists that may be
          published as part of HUBTYPE’ marketing and promotional efforts.
        </Paragraph>
        <Service>11.7 Notices</Service>
        <Paragraph>
          Any notice or communication here under shall be in writing and either
          personally delivered or recognized express delivery courier or
          certified or registered mail, prepaid and return receipt requested,
          addressed to the other party at its address specified herein, or at
          such other address designated in a subsequent notice. All notices
          shall be in English, effective upon receipt.
        </Paragraph>
        <Service>11.8 Assignment</Service>
        <Paragraph>
          Except as expressly provided herein, this Agreement and the rights and
          obligations here under may not be assigned, in whole or in part, by
          either party without the other party’s written consent. However,
          without consent, each party may assign this Agreement to any successor
          to all or substantially all of its business that concerns this
          Agreement (whether by sale of assets orequity, merger, consolidation
          or otherwise). Any assignment in violation of this Section 11.8 shall
          be deemed null and void ab initio. HUBTYPE may use contractors and
          other third party service providers in performing the Service provided
          that HUBTYPE shall be liable for the acts and omissions of it
          ssubcontractors to the same extent as for its own acts and omissions.
          This Agreement shall be binding upon, and inure to the benefit of, the
          successors, representatives and permitted assigns of the parties here
          to.
        </Paragraph>
        <Service>11.9 Independent Contractors</Service>
        <Paragraph>
          The parties shall be independent contractors under this Agreement, and
          nothing herein will constitute either party as the employer, employee,
          agentor representative of the other party, or both parties as joint
          venturers or partners for any purpose.
        </Paragraph>
        <Service>11.10 Severability</Service>
        <Paragraph>
          If any Term of this Agreement is deemed invalid or unenforceable, in
          whole or in part, such invalidity or unenforceability will only affect
          such provision or the part thereof that is deemed invalid or
          unenforceable, surviving in forcethe rest of the terms of the
          Agreement, and considering the affected provision or the part affected
          by it as not included.
        </Paragraph>
        <Service>11.11 No Third-Party Beneficiaries</Service>
        <Paragraph>
          This Agreement does not confer any benefits on any third party unless
          it expressly states that it does.
        </Paragraph>
        <Service>11.12 Miscellaneous</Service>
        <Paragraph>
          This Agreement may be executed in counter parts, which, taken
          together, will constitute one and the same instrument. The exchange of
          a fully executed Agreement (in counter parts or otherwise) by
          electronic means or in writing shall be sufficient to bind the parties
          to the terms and conditions of this Agreement.
        </Paragraph>
        <Subtitle>12. Definitions</Subtitle>
        {services.map((e, i) => (
          <ServiceContainer section={e.section}>{e.text}</ServiceContainer>
        ))}
        <Update>Last Updated: 20 July, 2018</Update>
      </Section>
      <Services {...props} />
      <Footer {...props} />
    </BreakpointProvider>
  )
}

export default TermsPage
